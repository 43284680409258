@import "@/styles/variables";

.about {
  padding: 100px 0;
  background-color: $secondary-color;

  .about-content {
    padding: 0 40px;

    .section-title {
      font-size: 36px;
      font-weight: bold;
      margin-bottom: 40px;
      color: #fff;
      text-align: left;
    }

    .about-description {
      font-size: 18px;
      line-height: 1.5;
      margin-bottom: 30px;
      color: #fff;
      text-align: left;
    }
  }

  .about-image {
    text-align: center;

    img {
      max-width: 100%;
      height: auto;
      max-height: 400px;
    }
  }

  // Center the content vertically
  .ant-row-middle {
    height: 100%;
  }
}
