@import "@/styles/variables";

.contact-section {
  min-height: 100vh;
  padding: 50px;
  text-align: center;
  background-color: $primary-color;
  color: $secondary-color;
}

.contact-title {
  color: $secondary-color;
}

.contact-form {
  max-width: 600px;
  margin: 0 auto;
}

.contact-submit {
  background: $tertiary-color;
  border-color: $tertiary-color;
}
