@import "@/styles/variables";

.hero {
  height: 90vh;
  background-color: $primary-color;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: end;

  .hero-content {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    width: 100%;
    height: 40vh; // Set the height of the content section
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: rgba($secondary-color, 0.9);
    color: #fff;
    padding: 1em;

    h1 {
      font-size: 3rem;
      margin-bottom: 10px;
    }

    p {
      font-size: 1.2rem;
      margin-bottom: 20px;
    }

    .hero-button {
      background-color: $primary-color;

      &:hover,
      &:focus {
        background-color: darken($secondary-color, 10%);
        border-color: darken($secondary-color, 10%);
      }
    }
  }

  // Fix the background image in place
  @media screen and (min-width: 992px) {
    background-attachment: fixed;
  }
}
