@import "@/styles/variables";

.header {
  background-color: $primary-color;
  position: fixed;
  width: 100%;
  z-index: 100;
  padding: 10px 40px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo {
    font-size: 24px;
    color: $secondary-color;
    font-weight: bold;
    display: flex;
    height: 100%;
    align-items: center;
    gap: 10px;
    .logo-img {
      width: 40px;
      height: 40px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 50%;
    }
  }

  .menu-desktop {
    float: right;
    margin-top: 16px;
    background-color: $primary-color;
    background-color: transparent;
    margin: 0;
    padding: 0;

    .ant-menu-item {
      margin: 0 20px;

      &:last-child {
        margin-right: 0;
      }

      .active {
        color: $tertiary-color;
        font-weight: bold;
      }
    }
  }

  .menu-mobile-button {
    float: right;
    margin-top: 16px;
  }

  .menu-mobile {
    .active {
      color: $tertiary-color;
      font-weight: bold;
    }
  }
}
