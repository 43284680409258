@import "@/styles/variables";

.footer {
  background-color: $secondary-color;
  color: $primary-color;
  text-align: center;
  padding: 20px;
}

.footer-text {
  color: $primary-color;
}
