@import "@/styles/variables";

.portfolio-section {
  min-height: 100vh;
  padding: 50px;
  text-align: center;
  background-color: $primary-color;
  color: $secondary-color;
}

.portfolio-title {
  color: $secondary-color;
}

.portfolio-row {
  justify-content: center;
}

.portfolio-card {
  background-color: $secondary-color;
  color: $primary-color;
  border: none;

  .ant-typography {
    color: $primary-color;
  }
}
