@import "@/styles/variables";

.services {
  padding: 100px 24px;

  .section-title {
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 60px;
  }

  .service-card {
    text-align: center;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    transition: box-shadow 0.3s ease-in-out;

    &:hover {
      box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
    }

    .service-icon {
      max-width: 100%;
      height: auto;
      margin-bottom: 20px;
    }

    .service-title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 20px;
      color: $primary-color;
    }

    .service-description {
      font-size: 16px;
      line-height: 1.5;
      color: $secondary-color;
    }
  }
}
